import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { IConsultaCNERespuesta } from '../proxies/interfaces/IConsultaCNERespuesta';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-resultado-cne',
  templateUrl: './resultado-cne.component.html',
  styleUrls: ['./resultado-cne.component.css']
})
export class ResultadoCneComponent implements OnInit {

  convinacionTeclas: string;
  convinacion: string = "KeyCKeyNKeyE";
  convinacionContador: number;
  mostrarContador: boolean;

  constructor(
    public dialogRef: MatDialogRef<ResultadoCneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  obj: IConsultaCNERespuesta;
  ngOnInit() {
    
    this.obj = this.data;
    this.convinacionTeclas = "";
    this.convinacionContador = 0;
    this.mostrarContador = false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    this.convinacionContador += 1;
    this.convinacionTeclas +=event.code;

    if(this.convinacionContador == 3){
      if(this.convinacionTeclas == this.convinacion){
        this.mostrarContador = true;
      }else{
        this.convinacionTeclas = "";
        this.convinacionContador = 0;
        this.mostrarContador = false;
      }
    }

  }
  
}
