import { Component, OnInit, ViewChild, ElementRef, Renderer } from '@angular/core';
import { NotificacionService } from '../notificacion.service';
import { ConsultaCneService } from '../proxies/consulta-cne.service';
import {RecaptchaComponent} from 'ng-recaptcha';
import { AppConstants } from '../AppConstants';
import { TipoMensaje } from '../TipoMensaje';
import { HttpClient } from '@angular/common/http';
import { ConsultaEstadisticaCNERequerimiento } from '../proxies/interfaces/IConsultaEstadisticaCNERequerimiento';
import { IConsultaEstadisticaCNERespuesta } from '../proxies/interfaces/IConsultaEstadisticaCNERespuesta';
@Component({
  selector: 'app-estadisticas-cne',
  templateUrl: './estadisticas-cne.component.html',
  styleUrls: ['./estadisticas-cne.component.css']
})
export class EstadisticasCneComponent implements OnInit {
  @ViewChild('recaptchaRef')
  
  recaptchaRef: RecaptchaComponent;
  tituloProceso: string = "ESTADISTICAS"
  isCaptcha: boolean;
  captchaResponse;
  desde;
  hasta;  
  isLoadingResults = false;
  ipAddress;  


  
  dataSource: any[] = []; // Aquí debes proporcionar los datos que deseas mostrar en el grid

  displayedColumns: string[] = ['fecha','web','movil','total'];//, 'column2', 'column3','column4']; // Aquí debes definir las columnas que deseas mostrar en el grid


  constructor(private consultaService: ConsultaCneService,private notificacionService: NotificacionService,
    private http: HttpClient, el: ElementRef) { }
  
  respuesta: IConsultaEstadisticaCNERespuesta;
  
  ngOnInit() {
    this.http.get<{ip:string}>('https://jsonip.com')
    .subscribe( data => {
      //console.log('th data', data);
      this.ipAddress = data.ip
      //console.log('th data', this.ipAddress);
    })

    this.isCaptcha = AppConstants.isCaptcha;
  }

  public resolved(response: string) {
    this.captchaResponse = response;
  }
  
  Consulta() {

      if (this.desde == null ||
      this.desde == undefined ) {
      this.notificacionService.alert("Información", "Fecha desde incorrecta", TipoMensaje.warning);
      return;
    }
    
    if (this.hasta == null ||
      this.hasta == undefined ) {
      this.notificacionService.alert("Información", "Fecha hasta incorrecta.", TipoMensaje.warning);
      return;
      }
      const desdeDate = this.parseDate(this.desde);
      const hastaDate = this.parseDate(this.hasta);
      

      if (hastaDate < desdeDate) {        
        this.notificacionService.alert("Información", "La fecha hasta no puede ser menor que la fecha desde", TipoMensaje.warning);
      return;
    }
    
    //SE QUITA ESTO PARA AMBIENTE DE PRUEBAS
    /*
    this.notificacionService.alert("Información", "esto es prueba22"+ this.captchaResponse, TipoMensaje.warning);
    return ;
    */
    if(this.isCaptcha){
      if (this.captchaResponse == null ||
        this.captchaResponse == undefined ||
        this.captchaResponse.length <= 0) {
        this.notificacionService.alert("Información", "Debe validar que no es un robot.", TipoMensaje.warning);
        return;
      }
    }   
   
        
    let req: ConsultaEstadisticaCNERequerimiento = {
      desde: this.desde,
      hasta: this.hasta,
      ip: this.ipAddress      
    };

    if(this.isCaptcha){
      req.recaptcharesponse = this.captchaResponse;
    }
    this.respuesta = null;
    this.isLoadingResults = true;

    this.consultaService.consultaEstadistica(req)
    .subscribe(respuesta => {
      this.isLoadingResults = false;
      let data = respuesta['Data'];       
          this.respuesta = data;
          this.dataSource = data; 
    },
    error => {
      console.log(error);
      this.isLoadingResults = false;
      this.notificacionService.alert("Error", "No se puede comunicar con el servidor. Por favor espere unos minutos y pruebe de nuevo.", TipoMensaje.error );
    });


  }
  // Función para convertir una cadena de texto con formato 'DD/MM/AAAA' a un objeto Date
  parseDate(dateString: string): Date | null {
    const day = parseInt(dateString.substr(0, 2), 10);
    const month = parseInt(dateString.substr(2, 2), 10) - 1;
    const year = parseInt(dateString.substr(4), 10);
  
    // Validar si los valores de día, mes y año son números válidos
    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return null;
    }
  
    return new Date(year, month, day);
  }
  

}
