import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstants } from '../AppConstants';
import { ConsultaCNERequerimiento } from './interfaces/IConsultaCNERequerimiento';
import { IConsultaCNERespuesta } from './interfaces/IConsultaCNERespuesta';
import { ConsultaEstadisticaCNERequerimiento } from './interfaces/IConsultaEstadisticaCNERequerimiento';
import { IConsultaEstadisticaCNERespuesta } from './interfaces/IConsultaEstadisticaCNERespuesta';
//import { IResonseCne } from './interfaces/IResponseCNE';
//import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ConsultaCneService {

   //variable initialization
   headers : HttpHeaders;
   _baseURL : string;
   
  
  constructor(private http: HttpClient
    //, private datePipe: DatePipe
    ) {
    
    this._baseURL = AppConstants.baseURL;
   }

  consulta(req: ConsultaCNERequerimiento) {   
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post<IConsultaCNERespuesta>(this._baseURL + 'api/ConsultaVotacionDomicilioElectoral2021', req, { headers: this.headers });    
  }

  consultaEstadistica(req: ConsultaEstadisticaCNERequerimiento) {   
    
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post<IConsultaEstadisticaCNERespuesta>(this._baseURL + 'api/ConsultaEstadisticas', req, { headers: this.headers });    
  }

  
}
