import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ConsultaComponent } from './consulta/consulta.component'
import { EstadisticasCneComponent } from './estadisticas-cne/estadisticas-cne.component';

const ROUTES: Routes = [    
    { path: '', redirectTo: '/', pathMatch: 'full'} ,
    {  path: 'estadisticas', component: EstadisticasCneComponent  },
    { path: '**',  component: ConsultaComponent}
  ];
  

  
@NgModule({
    imports: [RouterModule.forRoot(ROUTES)],    
    exports: [RouterModule],
    providers: []
})
export class ApproutingModule {

}