import { BrowserModule } from '@angular/platform-browser';
import { FormsModule}  from '@angular/forms';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MaterialModule } from './material-module';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatDatepicker } from '@angular/material';
import {ApproutingModule} from './app.router';
import { HttpClientModule } from '@angular/common/http';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { ConsultaComponent } from './consulta/consulta.component';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { ResultadoCneComponent } from './resultado-cne/resultado-cne.component';
//import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { EstadisticasCneComponent } from './estadisticas-cne/estadisticas-cne.component';


@NgModule({
  declarations: [
    AppComponent,
    ConsultaComponent,
    ResultadoCneComponent,
    EstadisticasCneComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    ApproutingModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SweetAlert2Module.forRoot(),
    MatFormFieldModule,
    MatInputModule,
    NgxMaskModule.forRoot()
  ],
  entryComponents: [
    ResultadoCneComponent
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: '6LfmDeQUAAAAAMDu6Ry4Bl9ugkNOynvGSwzmjd0U',
    } as RecaptchaSettings,
  }],
  bootstrap: [AppComponent]
})
export class AppModule {

}
