import { Injectable } from '@angular/core';
import swal from 'sweetalert2';
import { TipoMensaje } from './TipoMensaje';
import { SwalDefaults } from '@toverux/ngx-sweetalert2/di';
import { promise } from 'protractor';


@Injectable({
  providedIn: 'root'
})
export class NotificacionService {

  constructor() { }
  

  alert(titulo: string, mensaje: string, tipo: TipoMensaje)
  {
    if(tipo == TipoMensaje.success){
      swal(titulo, mensaje, "success");
    }
    if(tipo == TipoMensaje.warning){
      swal(titulo, mensaje, "warning");
    }
    if(tipo == TipoMensaje.question){
      swal(titulo, mensaje, "question");
    }
    if(tipo == TipoMensaje.error){
      swal(titulo, mensaje, "error");
    }
    if(tipo == TipoMensaje.info){
      swal(titulo, mensaje, "info");
    }
  }

 
}

