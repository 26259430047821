import { Component, OnInit, ViewChild, ElementRef, Renderer } from '@angular/core';
import { NotificacionService } from '../notificacion.service';
import { ConsultaCneService } from '../proxies/consulta-cne.service';
import { TipoMensaje } from '../TipoMensaje';
import { ConsultaCNERequerimiento } from '../proxies/interfaces/IConsultaCNERequerimiento';
import { MatDialog } from '@angular/material';
import { IConsultaCNERespuesta } from '../proxies/interfaces/IConsultaCNERespuesta';
import { ResultadoCneComponent } from '../resultado-cne/resultado-cne.component';
import { HttpClient } from '@angular/common/http';
import {RecaptchaComponent} from 'ng-recaptcha';
import { knownFolders, File, Folder } from "file-system";
import swal from 'sweetalert2';
import { EnumAccionRespuesta } from '../herramientas/Enumeraciones';

import { AppConstants } from '../AppConstants';

@Component({
  selector: 'app-consulta',
  templateUrl: './consulta.component.html',
  styleUrls: ['./consulta.component.css']
})
export class ConsultaComponent implements OnInit {

  @ViewChild('recaptchaRef')
  recaptchaRef: RecaptchaComponent;


  tituloProceso: string = "CONSULTA LUGAR DE VOTACIÓN";
  intentos: number = 3;
  fechaCorte: string = "El registro electoral se encuentra actualizado al 22 de enero de 2024.";



  isCaptcha: boolean;

  constructor(private consultaService: ConsultaCneService, private notificacionService: NotificacionService
    ,private dialog: MatDialog, private http: HttpClient, el: ElementRef, renderer: Renderer) {
      var events = 'cut copy paste';
      events.split(' ').forEach(e =>
      renderer.listen(el.nativeElement, e, (event) => {
        event.preventDefault();
        })
      );
     }
  captchaResponse;
  cedula;
  nombre;
  fecha;
  isLoadingResults = false;
  ipAddress;
  contadorConsulta: number;
  codigoProceso:number;
  respuesta: IConsultaCNERespuesta;
  consultasEnMinuto: number = 0;
  ultimoTiempoConsulta: Date;

  ngOnInit() {
    this.http.get<{ip:string}>('https://jsonip.com')
    .subscribe( data => {      
      this.ipAddress = data.ip
    })

    this.isCaptcha = AppConstants.isCaptcha;
    this.ultimoTiempoConsulta = new Date();

  }



  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  charterOnly(event): boolean{

    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)
      || charCode == 193 || charCode == 201 || charCode == 205 || charCode == 211 || charCode == 218
      || charCode == 225 || charCode == 233 || charCode == 237 || charCode == 243 || charCode == 250
      || charCode == 241 || charCode == 209 || charCode == 32  || charCode == 8     ) {
      return true;
    }
    return false;
  }

  public resolved(response: string) {
    this.captchaResponse = response;
  }

  limpiarFormulario():void{
    this.cedula = "";
          this.nombre = "";
          this.recaptchaRef.reset();
  }

  calcularDigitoVerificador(cedula: string): number {
    let sumaTotal = 0;
    for (let i = 0; i < cedula.length - 1; i++) {
      let digito = parseInt(cedula[i], 10);
      let resultado = (i % 2 === 0) ? digito * 2 : digito * 1;
      if (resultado >= 10) {
        resultado -= 9;
      }
      sumaTotal += resultado;
    }
    let digitoVerificador = (10 - (sumaTotal % 10)) % 10;
    return digitoVerificador;
  }

  validarDigitoVerificador(cedula: string): boolean {
    if (cedula.length !== 10 || isNaN(parseInt(cedula, 10))) {
      return false; // La cédula no tiene 10 dígitos o no es un número válido
    }
    let digitoVerificadorCalculado = this.calcularDigitoVerificador(cedula);
    let digitoVerificadorReal = parseInt(cedula[9], 10);
    return digitoVerificadorCalculado === digitoVerificadorReal;
  }

  Consulta() {

    const tiempoActual = new Date();
    const diferenciaTiempo = tiempoActual.getTime() - this.ultimoTiempoConsulta.getTime();
    if (diferenciaTiempo > 60000) { // 60000 milisegundos = 1 minuto
      this.consultasEnMinuto = 0; // Reinicia el contador si ha pasado más de un minuto
      this.ultimoTiempoConsulta = tiempoActual; // Actualiza el tiempo de la última consulta
    }

    // Verifica si se ha superado el límite de consultas en un minuto
    if (this.consultasEnMinuto >= this.intentos) {
      // Muestra un mensaje de advertencia al usuario
      this.notificacionService.alert("Advertencia", "Has realizado demasiadas consultas en un minuto. Por favor, espera un momento antes de intentar nuevamente.", TipoMensaje.warning);
      return; // Evita realizar la consulta
    }

    // Incrementa el contador de consultas en el último minuto
    this.consultasEnMinuto++;

    if (this.cedula == null ||
      this.cedula == undefined ||
      this.cedula.length != 10) {
      this.notificacionService.alert("Información", "El número de cédula debe contener 10 dígitos.", TipoMensaje.warning);
      return;
    }
    
    if (this.nombre == null ||
      this.nombre == undefined ||
      this.nombre.length < 8) {
      this.notificacionService.alert("Información", "Fecha de nacimiento incorrecta.", TipoMensaje.info);
      return;
      }


      if (!this.validarDigitoVerificador(this.cedula)) {
        this.notificacionService.alert("Información", "Cédula incorrecta.", TipoMensaje.warning);
        return;
      }

      //SE QUITA ESTO PARA AMBIEBTE DE PRUEBAS
    if(this.isCaptcha){
      if (this.captchaResponse == null ||
        this.captchaResponse == undefined ||
        this.captchaResponse.length <= 0) {
        this.notificacionService.alert("Información", "Debe validar que no es un robot.", TipoMensaje.warning);
        return;
      }
    }




    let req: ConsultaCNERequerimiento = {
      cedula: this.cedula,
      nombre: this.nombre,
      ip: this.ipAddress,
      contadorConsulta: this.contadorConsulta,
      codigoProceso: this.codigoProceso
    };

    if(this.isCaptcha){
      req.recaptcharesponse = this.captchaResponse;
    }

    this.respuesta = null;
    this.isLoadingResults = true;
    this.consultaService.consulta(req)
    .subscribe(respuesta => {
      this.isLoadingResults = false;

      let data = respuesta['Data'];

      switch(data.accionRespuesta){
        //error de búsqueda Y no empadronado
        case EnumAccionRespuesta.errorBusqueda:
        case EnumAccionRespuesta.noExistePadron:{
          this.notificacionService.alert("Información", data.error, TipoMensaje.info );
          this.limpiarFormulario();
        }break;
        //error padron pasivo
        case EnumAccionRespuesta.padronDesactivado:{

          if(data.activo == false){
            this.respuesta = data;

            swal({
              title: "<span style='color: #36a9e1;'>¡Alerta!</span>",
              html: data.error,
              type: TipoMensaje.warning,
             // imageUrl: 'assets/logo.png',
              imageWidth: 150,

              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Continuar',
              width : '30rem'

            })
            .then(
              (result) => {

                this.limpiarFormulario();
              }
            )
          }
        }break;
        //búsqueda correcta de registro padron
        case EnumAccionRespuesta.busquedaCorrecta:{
          this.respuesta = data;

          this.dialog.open(ResultadoCneComponent, {
            data: this.respuesta
          }).afterClosed().subscribe(result => {
            this.limpiarFormulario();
          })
        }break;

      }

    },
    error => {
      console.log(error);
      this.isLoadingResults = false;
      this.notificacionService.alert("Error", "No se puede comunicar con el servidor. Por favor espere unos minutos y pruebe de nuevo.", TipoMensaje.error );
    });
  }



}
